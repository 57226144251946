var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_delivery_order") } },
    [
      _c(
        "span",
        { attrs: { slot: "extra" }, slot: "extra" },
        [
          _vm._v(" " + _vm._s(_vm.dtDetailDO.deliveryOrderNumber || "-") + " "),
          _c("a-divider", { attrs: { type: "vertical" } }),
          _vm._v(" " + _vm._s(_vm.dtDetailDO.status || "-") + " ")
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              ref: "deliveryOrderForm",
              attrs: { model: _vm.form, rules: _vm.rules }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_customer_detail")) + " "
                        )
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_name"),
                            prop: "customerId"
                          }
                        },
                        [
                          _vm.isModeCreate
                            ? _c("CSelectCustomer", {
                                attrs: {
                                  "prop-customer-name": _vm.form.customer,
                                  "for-list": false
                                },
                                on: { "on-select": _vm.onSelectCustomer },
                                model: {
                                  value: _vm.form.customerId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerId", $$v)
                                  },
                                  expression: "form.customerId"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.customer || "-"))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_order_number"),
                            prop: "salesOrderNumbers"
                          }
                        },
                        [
                          _vm.isModeCreate
                            ? _c(
                                "span",
                                { staticClass: "ant-form-text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.form.customerId
                                          ? _vm.$t("lbl_choose_sales_order")
                                          : _vm.$t("lbl_select_customer_first")
                                      ) +
                                      " "
                                  ),
                                  _vm.form.customerId
                                    ? _c("a-icon", {
                                        staticClass: "ml-2",
                                        attrs: { type: "select" },
                                        on: { click: _vm.toggleSelectSO }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("CSelectMultiSalesOrder", {
                            attrs: { "prop-customer-id": _vm.form.customerId },
                            on: { "on-save": _vm.onSelectSalesOrder },
                            model: {
                              value: _vm.showSelectSO,
                              callback: function($$v) {
                                _vm.showSelectSO = $$v
                              },
                              expression: "showSelectSO"
                            }
                          }),
                          _vm.isModeCreate
                            ? _c(
                                "div",
                                { class: { "border-top": _vm.isModeCreate } },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(_vm.selectedSalesOrder || "-")
                                    )
                                  ])
                                ]
                              )
                            : _c(
                                "div",
                                _vm._l(_vm.form.salesOrderNumbers, function(
                                  item
                                ) {
                                  return _c("a-tag", { key: item }, [
                                    _vm._v(" " + _vm._s(item || "-") + " ")
                                  ])
                                }),
                                1
                              )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_contact"),
                            prop: "customerContact"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("a-input", {
                                attrs: {
                                  "allow-clear": "",
                                  placeholder: _vm.$t("lbl_type_here"),
                                  type: "tel"
                                },
                                model: {
                                  value: _vm.form.customerContact,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerContact", $$v)
                                  },
                                  expression: "form.customerContact"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.customerContact))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_po_number"),
                            prop: "customerPoNumber"
                          }
                        },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(_vm._s(_vm.form.customerPoNumber || "-"))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_delivery_detail")) + " "
                        )
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_shipping_address"),
                            prop: "customerShipToAddress"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("CSelectShippingAddresss", {
                                attrs: { "prop-address": _vm.customerAddress },
                                model: {
                                  value: _vm.form.customerShipToAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "customerShipToAddress",
                                      $$v
                                    )
                                  },
                                  expression: "form.customerShipToAddress"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  _vm._s(_vm.form.customerShipToAddress || "-")
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_eta_date"),
                            prop: "etaDate"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("a-date-picker", {
                                attrs: {
                                  placeholder: _vm.$t("lbl_choose"),
                                  format: _vm.DEFAULT_DATE_FORMAT
                                },
                                model: {
                                  value: _vm.form.etaDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "etaDate", $$v)
                                  },
                                  expression: "form.etaDate"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm._f("date")(_vm.form.etaDate)))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_eta_hour"),
                            prop: "etaHour"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("a-time-picker", {
                                attrs: {
                                  placeholder: _vm.$t("lbl_choose"),
                                  format: _vm.DEFAULT_TIME_FORMAT
                                },
                                on: { change: _vm.onChangeHour },
                                model: {
                                  value: _vm.form.etaHour,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "etaHour", $$v)
                                  },
                                  expression: "form.etaHour"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm._f("time")(_vm.form.etaDate)))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_freight"),
                            prop: "freight"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("CSelectFreight", {
                                model: {
                                  value: _vm.form.freight,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "freight", $$v)
                                  },
                                  expression: "form.freight"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.freight || "-"))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_courier_name"),
                            prop: "courierName"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("a-input", {
                                attrs: {
                                  "allow-clear": "",
                                  placeholder: _vm.$t("lbl_type_here")
                                },
                                model: {
                                  value: _vm.form.courierName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "courierName", $$v)
                                  },
                                  expression: "form.courierName"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.courierName || "-"))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_order_detail")) + " ")
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_order_date"),
                            prop: "orderDate"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("a-date-picker", {
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: _vm.$t("lbl_choose"),
                                  format: _vm.DEFAULT_DATE_FORMAT
                                },
                                model: {
                                  value: _vm.form.orderDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "orderDate", $$v)
                                  },
                                  expression: "form.orderDate"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  _vm._s(_vm._f("date")(_vm.form.orderDate))
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_branch"), prop: "branch" }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("CSelectBranch", {
                                model: {
                                  value: _vm.form.branch,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "branch", $$v)
                                  },
                                  expression: "form.branch"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.branchName || "-"))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_person_name"),
                            prop: "salesName"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("a-input", {
                                attrs: {
                                  placeholder: _vm.$t("lbl_type_here"),
                                  "allow-clear": ""
                                },
                                model: {
                                  value: _vm.form.salesName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "salesName", $$v)
                                  },
                                  expression: "form.salesName"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.salesName || "-"))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_type"),
                            prop: "salesType"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("CSelectSalesType", {
                                model: {
                                  value: _vm.form.salesType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "salesType", $$v)
                                  },
                                  expression: "form.salesType"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.salesType || "-"))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_notes") } },
                        [
                          !_vm.isUnbilled
                            ? _c("a-textarea", {
                                attrs: {
                                  placeholder: _vm.$t("lbl_type_here"),
                                  "auto-size": { minRows: 1, maxRows: 4 }
                                },
                                model: {
                                  value: _vm.form.notes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "notes", $$v)
                                  },
                                  expression: "form.notes"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.notes || "-"))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_payment_details")) + " "
                        )
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_bill_address"),
                            prop: "customerBillToAddress"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("CSelectBillAddress", {
                                attrs: { "prop-address": _vm.customerAddress },
                                model: {
                                  value: _vm.form.customerBillToAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "customerBillToAddress",
                                      $$v
                                    )
                                  },
                                  expression: "form.customerBillToAddress"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  _vm._s(_vm.form.customerBillToAddress || "-")
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency"),
                            prop: "currency"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("CSelectCurrency", {
                                attrs: { "prop-default": _vm.isModeCreate },
                                model: {
                                  value: _vm.form.currency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "currency", $$v)
                                  },
                                  expression: "form.currency"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.currencyName || "-"))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_term_of_payment"),
                            prop: "top"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("CSelectTermOfPayment", {
                                model: {
                                  value: _vm.form.top,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "top", $$v)
                                  },
                                  expression: "form.top"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.top || "-"))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_calculation"),
                            prop: "taxCalculation"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("CSelectTaxCalculation", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.taxCalculation,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "taxCalculation", $$v)
                                  },
                                  expression: "form.taxCalculation"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.taxCalculation || "-"))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_additional")) + " ")
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_dry_ice"),
                            prop: "dryIceQty"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("a-input-number", {
                                attrs: {
                                  placeholder: _vm.$t("lbl_type_here"),
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  min: 0
                                },
                                model: {
                                  value: _vm.form.dryIceQty,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "dryIceQty", _vm._n($$v))
                                  },
                                  expression: "form.dryIceQty"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toDecimalQty")(_vm.form.dryIceQty)
                                  )
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_box_type"),
                            prop: "boxId"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("CSelectBox", {
                                on: { "on-select": _vm.onSelectBox },
                                model: {
                                  value: _vm.form.boxId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "boxId", $$v)
                                  },
                                  expression: "form.boxId"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.boxName || "-"))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_box_qty"),
                            prop: "boxQty"
                          }
                        },
                        [
                          !_vm.isUnbilled
                            ? _c("a-input-number", {
                                attrs: {
                                  placeholder: _vm.$t("lbl_type_here"),
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  min: 0
                                },
                                model: {
                                  value: _vm.form.boxQty,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "boxQty", _vm._n($$v))
                                  },
                                  expression: "form.boxQty"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toDecimalQty")(_vm.form.boxQty)
                                  )
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              !_vm.isUnbilled
                ? _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "danger",
                            icon: "delete",
                            disabled: !_vm.selectedRowKeys.length
                          },
                          on: { click: _vm.deleteRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dtTable,
                        loading: _vm.loading.table,
                        "row-selection": {
                          selectedRowKeys: _vm.selectedRowKeys,
                          onChange: _vm.onRowSelect,
                          getCheckboxProps: function(r) {
                            return {
                              props: {
                                disabled: _vm.dtDetailDO.status && !_vm.isDraft
                              }
                            }
                          }
                        },
                        scroll: { y: 500 },
                        pagination: false
                      }
                    },
                    [
                      _c(
                        "a-table-column",
                        { key: "no", attrs: { "data-index": "no", width: 70 } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_number_short")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "productCode",
                          attrs: { "data-index": "productCode" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.productCode || "-") +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_product_code")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "productName",
                          attrs: { "data-index": "productName" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.productName || "-") +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_product_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "cutting",
                          attrs: { "data-index": "cutting", width: 100 },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(record.cutting || "-") + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_cutting")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "qty",
                          attrs: { "data-index": "qty", width: 100 },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toDecimalQty")(record.qty)
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_qty_order")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "batchNumber",
                          attrs: { "data-index": "batchNumber" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.batchNumber || "-") +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_batch_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "alias",
                          attrs: { "data-index": "alias" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(record.alias || "-") + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_invoice_description")))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c("div", { staticClass: "text-right" }, [
            _c("table", { staticClass: "w-100" }, [
              _c("tr", [
                _c("td", [
                  _c("p", { staticClass: "text-subtitle-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_shipping_cost")) + " ")
                  ])
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("lbl_delivery_fee")))]),
                _c(
                  "td",
                  { staticClass: "table-summary--value" },
                  [
                    !_vm.isUnbilled
                      ? _c("a-input-number", {
                          attrs: {
                            placeholder: _vm.$t("lbl_type_here"),
                            formatter: _vm.formatterNumber,
                            parser: _vm.reverseFormatNumber,
                            min: 0
                          },
                          model: {
                            value: _vm.vmAdditional.deliveryFee,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.vmAdditional,
                                "deliveryFee",
                                _vm._n($$v)
                              )
                            },
                            expression: "vmAdditional.deliveryFee"
                          }
                        })
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.vmAdditional.deliveryFee)
                            )
                          )
                        ])
                  ],
                  1
                )
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("lbl_dry_ice")))]),
                _c("td", { staticClass: "table-summary--value" }, [
                  _vm._v(
                    " " + _vm._s(_vm._f("currency")(_vm.findTotalDryIce)) + " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("lbl_box")))]),
                _c("td", { staticClass: "table-summary--value" }, [
                  _vm._v(
                    " " + _vm._s(_vm._f("currency")(_vm.findTotalBox)) + " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("lbl_total")))]),
                _c("td", { staticClass: "table-summary--value" }, [
                  _vm._v(" " + _vm._s(_vm._f("currency")(_vm.findTotal)) + " ")
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ])
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _vm.allowCancel
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "danger",
                                loading: _vm.loading.cancel
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleCancel(_vm.deliveryOrderId)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                          )
                        : _vm._e(),
                      _vm.isNeedApproval && _vm.hasPrivilegeApprovalDO
                        ? _c("a-button", { on: { click: _vm.showQC } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_show_qc")) + " ")
                          ])
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.allowStatusToPrint,
                              expression: "allowStatusToPrint"
                            }
                          ],
                          attrs: { loading: _vm.loading.print },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      ),
                      _vm.allowSaveDraft || !_vm.dtDetailDO.status
                        ? _c(
                            "a-button",
                            {
                              attrs: { loading: _vm.loading.draft },
                              on: {
                                click: function($event) {
                                  return _vm.validateForm("draft")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.allowSubmit || !_vm.dtDetailDO.status
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "save",
                                loading: _vm.loading.submit
                              },
                              on: {
                                click: function($event) {
                                  return _vm.validateForm("submit")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.modalQc
        ? _c("DeliveryOrderModalQC", {
            attrs: { "prop-do-id": _vm.deliveryOrderId },
            on: { "on-save": _vm.onSaveQC },
            model: {
              value: _vm.modalQc,
              callback: function($$v) {
                _vm.modalQc = $$v
              },
              expression: "modalQc"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }