





































































































































































































































































































































































































































































































































































































































import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseDetailProduct, ResponseListProduct, ResponseProduct } from "@interface/product.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { productService } from "@service/product.service";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import Vue from "vue";
import { ResponseDetailSalesOrder, ResponseSalesOrder } from "@interface/sales-order.interface";
import { deliveryOrderService } from "@service/delivery-order.service";
import { ContactData, IAddressDataList, ResponseContactData } from "@interface/contact.interface";
import { IOption } from "@interface/common.interface";
import moment, { Moment } from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "@/models/constant/date.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RequestDeliveryOrderCreate, RequestDeliveryOrderLineCreate, RequestDeliveryOrderUpdate, ResponseDeliveryOrderDetail, ResponseSubmitDeliveryOrder } from "@interface/delivery-order.interface";
import { Mode } from "@/models/enums/global.enum";
import { contactServices } from "@service/contact.service";
import { DELIVERY_ORDER_STATUS } from "@/models/enums/delivery-order.enum";
import { mapGetters } from "vuex";
import { IAuthorities } from "@interface/auth.interface";
import { printPDF } from "@helper/print-js";
import { Decimal } from "decimal.js-light";
import { removeDuplicate } from "@/helpers/common";

interface Row {
  key: any;
  no: number;
  productId: string;
  productCode: string;
  productName: string;
  cutting: string;
  batchNumber: string;
  alias: string;

  qty: number;
  qtyPick: number;
  qtyOutstanding: number
  qtyFinal?: number
  salesOrderId: string;
  salesOrderLineId: string;
  deliveryOrderLineId?: string;
}

const ALLOW_CANCEL: DELIVERY_ORDER_STATUS[] = [
  DELIVERY_ORDER_STATUS.DRAFT,
  DELIVERY_ORDER_STATUS.NEED_APPROVAL,
];

const ALLOW_PRINT: DELIVERY_ORDER_STATUS[] = [
  DELIVERY_ORDER_STATUS.BILLED,
  DELIVERY_ORDER_STATUS.DELIVERED,
  DELIVERY_ORDER_STATUS.NEED_APPROVAL,
  // DELIVERY_ORDER_STATUS.CANCELLED,
  // DELIVERY_ORDER_STATUS.DRAFT,
  DELIVERY_ORDER_STATUS.INVOICING,
  DELIVERY_ORDER_STATUS.PAID,
  DELIVERY_ORDER_STATUS.UNBILLED,
  DELIVERY_ORDER_STATUS.PARTIAL_INVOICING,
  DELIVERY_ORDER_STATUS.PARTIAL_PAID,
  DELIVERY_ORDER_STATUS.FULL_PAID,
  DELIVERY_ORDER_STATUS.RETURNED,
  DELIVERY_ORDER_STATUS.UNPAID,
];

const ALLOW_SAVE_DRAFT = [
  DELIVERY_ORDER_STATUS.DRAFT,
];

const ALLOW_SUBMIT = [
  ...ALLOW_SAVE_DRAFT,
];

export default Vue.extend({
  name: "DeliveryOrderForm",
  components: {
    CSelectCustomer: () => import(/*webpackPrefetch: true*/"@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectMultiSalesOrder: () => import(/*webpackPrefetch: true*/"@/components/shared/select-multi-sales-order/CSelectMultiSalesOrder.vue"),
    CSelectBox: () => import(/*webpackPrefetch: true*/"@/components/shared/select-box/CSelectBox.vue"),
    CSelectShippingAddresss: () => import(/*webpackPrefetch: true*/"@/components/shared/select-shipping-address/CSelectShippingAddress.vue"),
    CSelectBillAddress: () => import(/*webpackPrefetch: true*/"@/components/shared/select-bill-address/CSelectBillAddress.vue"),
    CSelectFreight: () => import(/*webpackPrefetch: true*/"@/components/shared/select-freight/CSelectFreight.vue"),
    CSelectSalesType: () => import(/*webpackPrefetch: true*/"@/components/shared/select-sales-type/CSelectSalesType.vue"),
    CSelectCurrency: () => import(/*webpackPrefetch: true*/"@/components/shared/select-currency/CSelectCurrency.vue"),
    CSelectTermOfPayment: () => import(/*webpackPrefetch: true*/"@/components/shared/select-term-of-payment/CSelectTermOfPayment.vue"),
    CSelectTaxCalculation: () => import(/*webpackPrefetch: true*/"@/components/shared/select-tax-calculation/CSelectTaxCalculation.vue"),
    DeliveryOrderModalQC: () => import(/*webpackPrefetch: true*/"./components/DeliveryOrderModalQC.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DEFAULT_TIME_FORMAT,
      form: {
        customerId: undefined as string | undefined,
        customer: undefined as string | undefined,
        salesOrderNumbers: [] as string[],
        customerContact: "",
        customerPoNumber: "",
        customerShipToAddress: undefined as string | undefined,
        etaDate: null as Moment | null,
        etaHour: null as Moment | null,
        freight: undefined as string | undefined,
        courierName: "",
        orderDate: null as Moment | null,
        branch: undefined as string | undefined,
        branchName: "",
        salesName: "",
        salesType: undefined as string | undefined,
        expiredDate: null as Moment | null,
        notes: "",
        customerBillToAddress: undefined as string | undefined,
        currency: undefined as string | undefined,
        currencyName: "",
        top: undefined as string | undefined,
        taxCalculation: TAX_CALCULATION.NONE,
        dryIceQty: 0,
        dryIceId: "",
        boxId: undefined as string | undefined,
        boxQty: 0,
        boxName: "",
      },
      rules: {
        customerId: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        salesOrderNumbers: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        courierName: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        dryIceQty: [{ required: false, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        boxId: [{ required: false, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        boxQty: [{ required: false, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        customerContact: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        customerPoNumber: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        customerShipToAddress: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        etaDate: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        etaHour: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        freight: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        orderDate: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        branch: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        salesName: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        salesType: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        customerBillToAddress: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        currency: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        top: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        taxCalculation: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
      },
      vmAdditional: {
        deliveryFee: 0,
      },
      selectedRowKeys: [] as string[],
      dtTable: [] as Row[],
      loading: {
        table: false,
        draft: false,
        submit: false,
        print: false,
        cancel: false,
      },
      showSelectSO: false,
      listSalesOrder: [] as ResponseSalesOrder[],
      customerAddress: [] as IAddressDataList[],
      dtListOutStanding: [] as ResponseDetailSalesOrder[],
      deliveryOrderId: "",
      deletedLines: [] as string[],
      dtDetailDO: {} as ResponseDeliveryOrderDetail,
      dtDryIce: {} as ResponseDetailProduct,
      dtBoxType: {} as ResponseDetailProduct,
      modalQc: false,
    };
  },
  computed: {
    ...mapGetters({
      usrPrivileges: "authStore/GET_USER_PRIVILEGES"
    }),
    allowStatusToPrint(): boolean {
      return ALLOW_PRINT.includes(this.dtDetailDO.status);
    },
    allowCancel(): boolean {
      return ALLOW_CANCEL.includes(this.dtDetailDO.status);
    },
    allowSaveDraft(): boolean {
      return ALLOW_SAVE_DRAFT.includes(this.dtDetailDO.status);
    },
    allowSubmit(): boolean {
      return ALLOW_SUBMIT.includes(this.dtDetailDO.status);
    },
    formCol() {
      return {
        label: {
          sm: { span: 24 },
          md: { span: 8 }
        },
        wrapper: {
          sm: { span: 24 },
          md: { span: 16 }
        },
      };
    },
    findTotalDryIce(): number {
      if (this.dtDryIce.uomConversions) {
        return new Decimal(this.dtDryIce.uomConversions[0].salePrice).times(this.form.dryIceQty).toNumber();
      }
      return 0;
    },
    findTotalBox(): number {
      if (this.dtBoxType.uomConversions) {
        return new Decimal(this.dtBoxType.uomConversions[0].salePrice).times(this.form.boxQty).toNumber();
      }
      return 0;
    },
    findTotal(): number {
      return new Decimal(this.vmAdditional.deliveryFee).plus(this.findTotalDryIce).plus(this.findTotalBox).toNumber();
    },
    selectedSalesOrder(): string {
      return this.listSalesOrder.map((x: ResponseSalesOrder) => x.salesOrderNumber).join(", ");
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    isUnbilled(): boolean {
      return this.dtDetailDO.status === DELIVERY_ORDER_STATUS.UNBILLED;
    },
    isNeedApproval(): boolean {
      return this.dtDetailDO.status === DELIVERY_ORDER_STATUS.NEED_APPROVAL;
    },
    isDraft(): boolean {
      return this.dtDetailDO.status === DELIVERY_ORDER_STATUS.DRAFT;
    },
    isCancelled(): boolean {
      return this.dtDetailDO.status === DELIVERY_ORDER_STATUS.CANCELLED;
    },
    hasPrivilegeApprovalDO(): boolean {
      return !!this.usrPrivileges.find((x: IAuthorities) => x.key === "approval-delivery-order");
    },
  },
  created() {
    this.getDryIce();
    if (this.$route.params.id) {
      this.deliveryOrderId = this.$route.params.id;
      this.getDetailDO(this.deliveryOrderId);
    }
  },
  methods: {
    reverseFormatNumber,
    formatterNumber,
    moment,
    getListMasterProducts(params: RequestQueryParamsModel): Promise<ResponseListProduct> {
      return productService.listProduct(params);
    },
    getDetailProduct(id: string): Promise<ResponseDetailProduct> {
      return productService.detailProduct(id);
    },
    getDetailCustomer(id: string): Promise<ResponseContactData> {
      return contactServices.getContactData(id);
    },
    getOutstandingBySO(id: string): Promise<ResponseDetailSalesOrder> {
      return deliveryOrderService.getOutstandingBySO(id);
    },
    getDetailDeliveryOrder(id: string): Promise<ResponseDeliveryOrderDetail> {
      return deliveryOrderService.getDetail(id);
    },
    createDO(payload: RequestDeliveryOrderCreate): Promise<ResponseDeliveryOrderDetail> {
      return deliveryOrderService.createDO(payload);
    },
    updateDO(id: string, payload: RequestDeliveryOrderUpdate): Promise<ResponseDeliveryOrderDetail> {
      return deliveryOrderService.updateDO(id, payload);
    },
    submitDO(id: string): Promise<ResponseSubmitDeliveryOrder> {
      return deliveryOrderService.submitDO(id);
    },
    getPrintFile(id: string): Promise<ArrayBuffer> {
      return deliveryOrderService.printDO(id);
    },
    handleBack(): void {
      this.$router.push({name: "sales.transactionsales.deliveryorder"});
    },
    showQC(): void {
      this.modalQc = true;
    },
    onSaveQC(): void {
      this.modalQc = false;
      this.getDetailDO(this.deliveryOrderId);
    },
    onSelectCustomer(e: {value: string, meta: IOption<ContactData>}): void {
      this.customerAddress = e.meta.meta?.addressDataList || [];
      const shipAddress = e.meta.meta?.addressDataList.find(x => x.shipTo && x.primaryShipTo);
      const billAddress = e.meta.meta?.addressDataList.find(x => x.billTo && x.primaryBillTo);
      this.form.customerShipToAddress = undefined;
      this.form.customerBillToAddress = undefined;
      if (shipAddress) {
        this.form.customerShipToAddress = `${shipAddress.address}, ${shipAddress.cityDistrict}, ${shipAddress.country}, ${shipAddress.postalCode}`;
      }
      if (billAddress) {
        this.form.customerBillToAddress = `${billAddress.address}, ${billAddress.cityDistrict}, ${billAddress.country}, ${billAddress.postalCode}`;
      }
      this.listSalesOrder = [];
    },
    deleteRow(): void {
      const { dtTable } = this;
      let dt: Row[] = [];
      dtTable.forEach(x => {
        if (!this.selectedRowKeys.includes(x.key)) {
          dt.push(x);
        } else {
          if (x.deliveryOrderLineId) {
            this.deletedLines.push(x.deliveryOrderLineId);
          }
        }
      });
      dt.forEach((x, i) => {
        x.no = i + 1;
        x.key = i;
      });
      this.selectedRowKeys = [];
      this.dtTable = dt;
    },
    onRowSelect(rowKey: []): void {
      this.selectedRowKeys = rowKey;
    },
    toggleSelectSO(): void {
      this.showSelectSO = true;
    },
    async onSelectBox(e: {value: string, meta: ResponseProduct}): Promise<void> {
      try {
        const res = await this.getDetailProduct(e.meta.id);
        this.dtBoxType = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    onChangeHour(): void {
      let { etaDate, etaHour } = this.form;
      if (etaDate && etaHour) {
        this.etaDate = etaDate.set("hour", etaHour.hour());
        this.etaDate = etaDate.set("minute", etaHour.minute());
      }
    },
    onSelectSalesOrder(data: ResponseSalesOrder[]): void {
      this.listSalesOrder = [...data];
      this.form.salesOrderNumbers = removeDuplicate(data.map(x => x.salesOrderNumber));
      this.deletedLines = [];
      this.getOutstanding([...this.listSalesOrder]);
    },
    async getDryIce(): Promise<void> {
      try {
        const res = await this.getListMasterProducts({ search: "code~*#ICE" });
        const detail = await this.getDetailProduct(res.data[0].id);
        this.dtDryIce = detail;
        this.form.dryIceId = detail.id;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    constructReqCreate(): RequestDeliveryOrderCreate {
      let deliveryOrderLines: RequestDeliveryOrderLineCreate[] = [];
      deliveryOrderLines = this.dtTable.map((x: Row) => ({
        id: (this.deliveryOrderId ? (x.deliveryOrderLineId || null) : null),
        qty: x.qtyOutstanding || 0,
        qtyPick: x.qtyOutstanding, // means all qty should deliver
        salesOrderId: x.salesOrderId,
        salesOrderLineId: x.salesOrderLineId,
      }));
      return {
        boxId: this.form.boxId || "",
        boxQty: this.form.boxQty || 0,
        branchId: this.form.branch || "",
        courierName: this.form.courierName,
        currencyId: this.form.currency || "",
        customerBillToAddress: this.form.customerBillToAddress || "",
        customerContact: this.form.customerContact,
        customerId: this.form.customerId || "",
        customerPo: this.form.customerPoNumber,
        customerShipToAddress: this.form.customerShipToAddress || "",
        deliveryFee: this.vmAdditional.deliveryFee,
        deliveryOrderLines,
        dryIceId: this.form.dryIceId,
        dryIceQty: this.form.dryIceQty ?? 0,
        etaDate: this.form.etaDate ? this.form.etaDate.format() : "",
        expireDate: this.form.expiredDate ? this.form.expiredDate.format() : "",
        freight: this.form.freight || "",
        note: this.form.notes,
        orderDate: this.form.orderDate ? this.form.orderDate.format() : "",
        salesName: this.form.salesName,
        salesType: this.form.salesType || "",
        taxCalculation: this.form.taxCalculation.toUpperCase(),
        top: this.form.top ? +this.form.top : 0,
      };
    },
    constructReqUpdate(): RequestDeliveryOrderUpdate {
      return {
        ...this.constructReqCreate(),
        deletedDeliveryOrderLineIds: this.deletedLines
      };
    },
    validateTable(): boolean {
      return !!this.dtTable.length;
    },
    validateForm(type: "draft" | "submit"): void {
      const form = this.$refs.deliveryOrderForm as any;
      form.validate((valid: boolean) => {
        if (valid && this.validateTable()) {
          if (type === "draft") {
            this.handleSubmitDraft();
          } else {
            this.handleSubmit();
          }
        } else {
          this.showNotifValidationError();
        }
      });
    },
    async handleSubmitDraft(): Promise<void> {
      try {
        this.loading.draft = true;
        if (this.deliveryOrderId) {
          const payload = this.constructReqUpdate();
          await this.updateDO(this.deliveryOrderId, payload);
          this.getDetailDO(this.deliveryOrderId);
          this.showUpdateSuccessMesssage();
        } else {
          const payload = this.constructReqCreate();
          const { id } = await this.createDO(payload);
          this.$router.replace({
            name: "sales.transactionsales.deliveryorder.edit",
            params: { id },
          });
          this.showCreateSuccessMesssage();
        }
        this.deletedLines = [];
      } catch (error) {
        this.showErrorMessage("notif_create_fail");
      } finally {
        this.loading.draft = false;
      }
    },
    async handleSubmit(): Promise<void> {
      try {
        this.loading.submit = true;
        if (this.deliveryOrderId) {
          await this.submitDO(this.deliveryOrderId);
          this.getDetailDO(this.deliveryOrderId);
        } else {
          const payload = this.constructReqCreate();
          const { id } = await this.createDO(payload);
          await this.submitDO(id);
          this.$router.replace({
            name: "sales.transactionsales.deliveryorder.detail",
            params: { id },
          });
        }
        this.showSubmitSuccessMesssage();
      } catch (error) {
        this.showErrorMessage("notif_create_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async getDetailDO(id: string): Promise<void> {
      try {
        const res = await this.getDetailDeliveryOrder(id);
        this.dtDetailDO = res;
        this.fillFormFromDetail(res);
        this.getCustomerDetail(res.customerId);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    async getCustomerDetail(id: string): Promise<void> {
      try {
        const { addressDataList } = await this.getDetailCustomer(id);
        this.customerAddress = addressDataList;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    fillFormFromDetail(data: ResponseDeliveryOrderDetail): void {
      this.form.customerId = data.customerId;
      this.form.customer = data.customerName;
      this.form.salesOrderNumbers = removeDuplicate(data.deliveryOrderLines.map(x => x.salesOrderNumber));
      this.form.customerContact = data.customerContact;
      this.form.customerPoNumber = data.customerPo;
      this.form.customerShipToAddress = data.customerShipToAddress;
      this.form.etaDate = this.moment(data.etaDate);
      this.form.etaHour = this.moment(data.etaDate);
      this.form.freight = data.freight;
      this.form.courierName = data.courierName;
      this.form.orderDate = this.moment(data.orderDate);
      this.form.branch = data.branchId;
      this.form.branchName = data.branch;
      this.form.salesName = data.salesName;
      this.form.salesType = data.salesType;
      this.form.notes = data.note;
      this.form.customerBillToAddress = data.customerBillToAddress;
      this.form.currency = data.currencyId;
      this.form.currencyName = data.currency;
      this.form.top = data.top + "";
      this.form.taxCalculation = data.taxCalculation;
      this.form.dryIceQty = data.dryIceQty;
      this.form.boxId = data.boxId;
      this.form.boxName = data.boxName;
      this.form.boxQty = data.boxQty;
      this.vmAdditional.deliveryFee = data.deliveryFee;

      this.fillTableFromDetail(data);
    },
    fillTableFromDetail(data: ResponseDeliveryOrderDetail) {
      this.dtTable = data.deliveryOrderLines.map((x, i) => ({
        key: x.id,
        no: i + 1,
        deliveryOrderLineId: x.id,
        ...x,
        alias: x.alias || ""
      }));
    },
    async getOutstanding(salesOrder: ResponseSalesOrder[]): Promise<void> {
      try {
        this.loading.table = true;
        const promises: Promise<ResponseDetailSalesOrder>[] = [];
        salesOrder.forEach(x => {
          promises.push(this.getOutstandingBySO(x.id));
        });
        const res = await Promise.all(promises);
        this.fillForm(res);
        this.fillTable(res);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
      }
    },
    fillForm(data: ResponseDetailSalesOrder[]): void {
      if (!data || !data.length) return;
      const [ val ] = data;
      this.form.customerContact = val.customerContact;
      this.form.customerPoNumber = data.map(x => x.customerPoNumber).join(", ");
      this.form.etaDate = this.moment(val.etaDate);
      this.form.etaHour = this.moment(val.etaDate);
      this.form.freight = val.freight;
      this.form.orderDate = this.moment(val.orderDate);
      this.form.branch = val.branchId;
      this.form.salesName = val.salesName;
      this.form.salesType = val.salesType;
      this.form.notes = val.notes;
      this.form.currency = val.currencyId;
      this.form.top = val.termOfPayment + "";
      this.form.taxCalculation = val.taxCalculation;
      this.form.customerShipToAddress = val.customerShipToAddress;
      this.form.customerBillToAddress = val.customerBillToAddress;
    },
    fillTable(data: ResponseDetailSalesOrder[]): void {
      let lines: Row[] = [];
      data.forEach(x => {
        x.salesOrderLines.forEach((y, i) => {
          const newLine: Row = {
            key: i,
            no: i + 1,
            productId: y.productId,
            productCode: y.productCode,
            productName: y.productName,
            cutting: y.cutting,
            batchNumber: y.batchNumber,
            qty: y.qty,
            qtyPick: y.qty,
            qtyFinal: y.qtyFinal,
            qtyOutstanding: y.qtyOutstanding,
            salesOrderId: x.id,
            salesOrderLineId: y.id,
            alias: y.alias,
          };
          lines = [...lines, newLine];
        });
      });
      lines.forEach((x, i) => {
        x.key = i;
        x.no = i + 1;
      });
      this.dtTable = lines;
    },
    async handlePrint(): Promise<void> {
      try {
        this.loading.print = true;
        const file = await this.getPrintFile(this.deliveryOrderId);
        printPDF(new Blob([file]), () => {
          this.showErrorMessage("error_print_fail");
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.print = false;
      }
    },
    async handleCancel(id: string): Promise<void> {
      try {
        if (!id) return;
        this.loading.cancel = true;
        await deliveryOrderService.cancel(id);
        this.showNotifSuccess("notif_cancel_success");
        this.getDetailDO(id);
      } catch (error) {
        this.showErrorMessage("notif_cancel_fail");
      } finally {
        this.loading.cancel = false;
      }
    },
  }
});
